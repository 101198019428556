import React, { createContext, FormEventHandler, PropsWithChildren, useCallback, useImperativeHandle } from 'react';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { SubmitHandler, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import * as O from 'fp-ts/Option';
import * as IO from 'fp-ts/IO';
import { pipe } from 'fp-ts/function';
import { useBooleanState } from '@shared/hooks/boolean';
import { usePrompt } from '@shared/modules/form/hooks';
import { To } from 'react-router-dom';
import { FormPreventLeaveModal } from '@shared/modules/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export interface EnhancedFormContextValue<Values extends FieldValues>
  extends Omit<UseFormReturn<Values>, 'handleSubmit'> {
  handleSubmit: FormEventHandler<HTMLFormElement>;
}

export const EnhancedFormContext = createContext<EnhancedFormContextValue<any>>(undefined as any);

export type EnhancedFormSubmitResult = TE.TaskEither<unknown, O.Option<To>>;

export interface EnhancedFormInnerProps<Values extends FieldValues> {
  form: UseFormReturn<Values>;
  preventLeave?: boolean;
  onSubmit: (values: Values) => EnhancedFormSubmitResult;
}

export type EnhancedFormExposedMethods = {
  handleSubmit: IO.IO<void>;
};

function EnhanceFormInner<Values extends FieldValues>(
  { form, preventLeave, onSubmit, children }: PropsWithChildren<EnhancedFormInnerProps<Values>>,
  ref: React.ForwardedRef<EnhancedFormExposedMethods>,
) {
  const [modalVisible, openModal, closeModal] = useBooleanState(false);
  const [isSubmitting, setSubmitting, unsetSubmitting] = useBooleanState(false);
  const [handleLeave, forceNavigate, resetWaitingTransition] = usePrompt(
    openModal,
    !!preventLeave && form.formState.isDirty,
  );

  const handleSubmit = (values: Values) =>
    pipe(
      TE.fromIO(setSubmitting),
      TE.chain(() => onSubmit(values)),
      TE.fold(
        () => T.fromIO(closeModal),
        to =>
          T.fromIO(() => {
            if (modalVisible) {
              return handleLeave();
            } else if (O.isSome(to)) {
              return forceNavigate(to.value);
            }
          }),
      ),
      T.chainFirstIOK(() => () => {
        form.reset(undefined, { keepValues: true });
        resetWaitingTransition();
        unsetSubmitting();
      }),
    )();

  const handleSubmitAndLeave = form.handleSubmit(handleSubmit as SubmitHandler<Values>, closeModal);

  useImperativeHandle(ref, () => ({
    handleSubmit: form.handleSubmit(handleSubmit as SubmitHandler<Values>),
  }));

  const setValue = useCallback<UseFormSetValue<Values>>(
    (name, value, options = {}) =>
      form.setValue(name, value, {
        ...options,
        shouldDirty: true,
        shouldValidate: form.formState.isSubmitted,
      }),
    [form],
  );

  const ctx: EnhancedFormContextValue<Values> = {
    ...form,
    setValue,
    handleSubmit: form.handleSubmit(handleSubmit as SubmitHandler<Values>),
  };

  const handleCloseModal = () => {
    closeModal();
    resetWaitingTransition();
  };

  return (
    <>
      {preventLeave && (
        <FormPreventLeaveModal
          open={modalVisible}
          onLeave={handleLeave}
          onClose={handleCloseModal}
          submitting={isSubmitting}
          onSubmitAndLeave={handleSubmitAndLeave}
        />
      )}

      <EnhancedFormContext.Provider value={ctx}>{children}</EnhancedFormContext.Provider>
    </>
  );
}

// Redecalare forwardRef
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const EnhancedForm = React.forwardRef(EnhanceFormInner);

export default EnhancedForm;
