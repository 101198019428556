import React from 'react';

import { Filter, FilterChildren, SearchFilter } from '@shared/modules/filter/model';
import { renderNullable } from '@shared/utils/render';
import SearchFilterComponent from './search/SearchFilter';
import { Box } from '@mantine/core';

interface FiltersProps<F extends Filter> {
  filter: F;
  search?: F extends SearchFilter ? boolean : never;
  onFilter: (filter: F) => void;
  children?: FilterChildren<F>;
}

function Filters<F extends Filter>({ filter, search, onFilter, children }: FiltersProps<F>) {
  const handleSearch = (search: string | null) => onFilter({ ...filter, search });

  return (
    <Box mb={15}>
      {search ? <SearchFilterComponent defaultValue={filter.search as any} onChange={handleSearch} /> : null}

      {renderNullable(children, children => children({ filter, onChange: onFilter }))}
    </Box>
  );
}

export default Filters;
