import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import ActiveNavLink from '@core/router/components/ActiveNavLink';

export const NavContent = styled.div`
  position: relative;
  height: 100%;
  background: ${props => props.theme.colors.violet[6]};
  padding: 10px;
`;

export const NavHeader = styled.div`
  padding: 5px 0 15px;
  border-bottom: 1px solid ${props => props.theme.fn.rgba(props.theme.colors.blue[1], 0.5)};
`;

export const NavHeaderLogo = styled(Link)`
  display: block;
  width: 140px;
  margin: 0 auto;
  background: #fff;
  padding: 10px 20px;
  border-radius: 20px;

  img {
    width: 100%;
  }
`;

export const NavGroup = styled.div`
  padding: 10px 0 30px;
`;

export const NavEntries = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const NavItem = styled(ActiveNavLink)`
  display: grid;
  padding: 7px 10px;
  grid-template-columns: 26px 1fr;
  align-items: center;
  grid-gap: 15px;
  color: ${props => props.theme.colors.dark[9]};
  text-decoration: none;
  border-radius: 10px;
  margin-top: 5px;
  background: transparent;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${props => props.theme.colors.violet[4]};
  }

  &.active {
    background-color: ${props => props.theme.colors.violet[4]};
  }
`;

export const NavItemIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 4px;
  width: 26px;
  height: 26px;

  svg {
    width: 14px;
    height: 14px;
    stroke: ${props => props.theme.colors.violet[4]};
  }
`;

export const NavFooter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 10px;
  padding: 0 15px;
  align-items: center;
  height: 100%;
  min-height: 56px;
  background: ${props => props.theme.colors.violet[6]};
  border-top: 1px solid ${props => props.theme.colors.gray[2]};

  a {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
  }
`;
