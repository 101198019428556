import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import dashboardDetailRoute from '@modules/dashboard/DetailPage';

const dashboardRoutes = (
  <Route path="dashboard">
    <Route index {...createRoute(dashboardDetailRoute)} />
  </Route>
);

export default dashboardRoutes;
