import { Newtype } from 'newtype-ts';
import z from 'zod';
import { emailSchema, nonEmptyStringSchema } from '@shared/schemas';
import { OrganisationId } from '@modules/dashboard/model';

export type UserId = Newtype<{ readonly ID: unique symbol }, string> & string;
export type AdminUserOrganisationId = Newtype<{ readonly ID: unique symbol }, string> & string;

export enum UserRole {
  Admin = 'admin',
  Animator = 'animator',
}

export const userRoleLabel: Record<UserRole, string> = {
  [UserRole.Admin]: 'Administrateur',
  [UserRole.Animator]: 'Animateur',
};

export const userRoleColor: Record<UserRole, string> = {
  [UserRole.Admin]: 'violet',
  [UserRole.Animator]: 'orange',
};

export interface AdminUserOrganisation {
  id: AdminUserOrganisationId;
  name: string;
}

export interface User {
  id: UserId;
  email: string;
  firstName: string | null;
  lastName: string | null;
  role: UserRole;
  organisation: AdminUserOrganisation | null;
}

export const userSchema = z.object({
  email: emailSchema,
  firstName: nonEmptyStringSchema,
  lastName: nonEmptyStringSchema,
  role: z.nativeEnum(UserRole),
  organisationId: z.string(),
});

export type CreateUserParams = z.infer<typeof userSchema>;

export type UpdateUserParams = Omit<CreateUserParams, 'email'>;

export interface Organisations {
  id: OrganisationId;
  name: string;
}
