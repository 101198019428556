import logo from '@assets/logos/logo.svg';
import { Profile } from '@modules/auth/model';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import * as Styled from './Nav.styles';
import { ActionIcon, Anchor, Avatar, Navbar, Text } from '@mantine/core';
import { IconDashboard, IconLogout, IconUsers } from '@tabler/icons-react';
import { renderNullable } from '@shared/utils/render';
import { getUserInital, getUserName } from '@modules/users/utils';
import { UserRole } from '@modules/users/model';

interface NavEntry {
  title: string;
  to: string;
  icon: ReactNode;
}

interface NavGroup {
  title?: string;
  entries: Array<NavEntry>;
}

function getEntries(role: UserRole): Array<NavGroup> {
  switch (role) {
    case UserRole.Animator:
      return [
        {
          entries: [{ title: 'Tableau de bord', to: '/dashboard', icon: <IconDashboard /> }],
        },
      ];

    case UserRole.Admin:
      return [
        {
          entries: [
            { title: 'Tableau de bord', to: '/dashboard', icon: <IconDashboard /> },
            { title: 'Utilisateurs', to: '/users', icon: <IconUsers /> },
          ],
        },
      ];
  }
}

interface NavProps {
  profile: Profile;
}

const Nav: FC<NavProps> = ({ profile }) => {
  const entries = getEntries(profile.role);

  return (
    <>
      <Navbar width={{ base: 270 }}>
        <Navbar.Section grow>
          <Styled.NavContent>
            <Styled.NavHeader>
              <Styled.NavHeaderLogo to="/">
                <img src={logo} alt="logo" width={100} height={66} />
              </Styled.NavHeaderLogo>
            </Styled.NavHeader>

            {entries.map((group, i) => (
              <Styled.NavGroup key={i}>
                {renderNullable(group.title, title => (
                  <Text fw={600} px={10} mb={10} color="white">
                    {title}
                  </Text>
                ))}

                <Styled.NavEntries>
                  {group.entries.map((entry, j) => (
                    <li key={j}>
                      <Styled.NavItem to={entry.to}>
                        <Styled.NavItemIcon>{entry.icon}</Styled.NavItemIcon>

                        <Text size={14} color="white">
                          {entry.title}
                        </Text>
                      </Styled.NavItem>
                    </li>
                  ))}
                </Styled.NavEntries>
              </Styled.NavGroup>
            ))}
          </Styled.NavContent>
        </Navbar.Section>

        <Navbar.Section>
          <Styled.NavFooter>
            <Avatar size="sm" radius="xl" color="beige" variant="filled">
              <Text color="violet">{getUserInital(profile.email, profile.firstName, profile.lastName)}</Text>
            </Avatar>

            <Anchor component={Link} to="/profile" fw={600} color="white" fz="sm">
              {getUserName(profile.email, profile.firstName, profile.lastName)}
            </Anchor>

            <ActionIcon component={Link} to="/logout" size="sm" color="violet" variant="outline">
              <IconLogout size="14" />
            </ActionIcon>
          </Styled.NavFooter>
        </Navbar.Section>
      </Navbar>
    </>
  );
};

export default Nav;
