import { HttpRange, httpService, HttpTask } from '@core/http';
import { CreateUserParams, Organisations, UpdateUserParams, User, UserId } from '@modules/users/model';
import { SearchFilter } from '@shared/modules/filter';

const URI = '/users';
export function getUsersRange(page: number, filter: SearchFilter): HttpRange<User, SearchFilter> {
  return httpService.getRange(URI, page, filter);
}

export function createUser(params: CreateUserParams): HttpTask<User> {
  return httpService.post(URI, params);
}

export function getUser(id: UserId): HttpTask<User> {
  return httpService.get(`${URI}/${id}`);
}

export function updateUser(id: UserId, params: UpdateUserParams): HttpTask<User> {
  return httpService.post(`${URI}/${id}`, params);
}

export function deleteUser(id: UserId): HttpTask {
  return httpService.delete(`${URI}/${id}`);
}

export function getOrganisations(): HttpTask<Array<Organisations>> {
  return httpService.get('/organisations');
}
