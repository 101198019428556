import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import * as UsersService from './service';
import Page, { PageProps } from '@layout/page/Page';
import { useRange, useVirtualizedTableColumns, VirtualizedList } from '@shared/modules/range';
import { SearchFilter, searchFilterParser } from '@shared/modules/filter';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { getPageQuery } from '@shared/modules/range/utils';
import { Filters } from '@shared/modules/filter/components';
import { identity } from 'fp-ts/function';
import { SharedButtonLink } from '@styles/shared';
import { User, UserRole, userRoleLabel } from '@modules/users/model';
import { Badge } from '@mantine/core';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);

    return httpTaskToResponseTask(UsersService.getUsersRange(getPageQuery(queries), searchFilterParser(queries)));
  },
});

const UsersListPage: FC = () => {
  const { range, handleLoadPage, handleFilter } = useRange<typeof loader, User, SearchFilter>(identity);

  const pageProps: PageProps = {
    title: 'Liste des utilisateurs',
    bottom: {
      right: (
        <SharedButtonLink btnType="create" to="new">
          Ajouter un utilisateur
        </SharedButtonLink>
      ),
    },
  };

  const { header, row } = useVirtualizedTableColumns<User>(
    [
      {
        key: 'lastName',
        label: 'Nom',
      },
      {
        key: 'firstName',
        label: 'Prénom',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'role',
        label: 'Rôle',
        width: '125px',
        style: { textAlign: 'right' },
        headerStyle: { textAlign: 'right' },
        renderer: ({ role }) => (
          <Badge color={role === UserRole.Admin ? 'violet' : 'orange'} variant="light">
            {userRoleLabel[role]}
          </Badge>
        ),
      },
    ],
    {
      rowLinkBuilder: user => user.id,
    },
  );

  return (
    <Page {...pageProps}>
      <Filters filter={range.filter} onFilter={handleFilter} search />

      <VirtualizedList header={header} range={range} loadPage={handleLoadPage}>
        {row}
      </VirtualizedList>
    </Page>
  );
};

const usersListRoute = defineRoute({
  component: UsersListPage,
  loader,
});

export default usersListRoute;
