import React, { FC, PropsWithChildren } from 'react';
import { renderNullable } from '@shared/utils/render';
import logo from '@assets/logos/logo.svg';

import * as Styled from './AuthLayout.styles';
import { Text } from '@mantine/core';

interface AuthLayoutProps {
  title?: string;
}

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({ title, children }) => {
  return (
    <Styled.AuthLayoutContainer align="center" position="center" p="md">
      <Styled.AuthLayoutContent shadow="xs" p={30} radius={10} withBorder={false}>
        <Styled.AuthLayoutLogoContainer>
          <img src={logo} width={213} height={141} alt="Logo Modoux" />
        </Styled.AuthLayoutLogoContainer>

        {renderNullable(title, title => (
          <Text align="center" fz="xl" fw="bold" mb="md">
            {title}
          </Text>
        ))}

        {children}
      </Styled.AuthLayoutContent>

      <Styled.AuthLayoutLogoContainer />
    </Styled.AuthLayoutContainer>
  );
};

export default AuthLayout;
