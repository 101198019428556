import React, { FC } from 'react';
import AuthLayout from '@modules/auth/components/layout/AuthLayout';
import LoginForm from '@modules/auth/components/login/LoginForm';
import { authenticateSchema } from '@modules/auth/model';
import * as AuthService from '@modules/auth/service';
import Seo from '@shared/modules/seo/Seo';
import * as EI from 'fp-ts/Either';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { getStringQuery } from '@shared/modules/filter';
import { constFalse } from 'fp-ts/function';

const actions = {
  authenticate: defineAction({
    type: 'authenticate',
    payload: authenticateSchema,
    handler: ({ payload }) => AuthService.authenticate(payload),
    flashOptions: {
      error: constFalse,
    },
    redirect: ({ request, result }) => {
      if (EI.isRight(result)) {
        return getStringQuery(parseQueriesFormUrl(request.url), 'referrer') ?? '/';
      }

      return null;
    },
  }),
};

const LoginPage: FC = () => {
  const [loading, authenticate, error] = useAction(actions.authenticate);

  return (
    <AuthLayout>
      <Seo title="S'identifier" />
      <LoginForm loading={loading} error={error} onSubmit={authenticate} />
    </AuthLayout>
  );
};

const loginRoute = defineRoute({
  component: LoginPage,
  actions,
});

export default loginRoute;
