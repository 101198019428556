import Nav from '@layout/nav/Nav';
import React, { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import * as Styled from './Layout.styles';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';
import { Text, Title } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useProfile } from '@modules/auth/profile-loader';

const Layout: FC = () => {
  const profile = useProfile();

  return (
    <>
      <Styled.LayoutLock p="md" align="center">
        <IconAlertTriangle size={50} />
        <Title>On dirait que vous êtes sur un appareil mobile.</Title>
        <Text>
          Pour une meilleure expérience utilisateur, nous vous invitons à vous connecter depuis un ordinateur et à
          activer le mode « plein écran » de votre navigateur.
        </Text>
      </Styled.LayoutLock>

      <Styled.LayoutContainer>
        <Nav profile={profile} />

        <Styled.LayoutContent>
          <Suspense fallback={<DebouncedLineLoader />}>
            <Outlet />
          </Suspense>
        </Styled.LayoutContent>
      </Styled.LayoutContainer>
    </>
  );
};

export default Layout;
