import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { EnhancedForm, mapToEnhancedFormSubmitResult, useEnhancedForm } from '@shared/modules/form';
import { UpdateProfileParams, updateProfileSchema } from '@modules/auth/model';
import { zodResolver } from '@hookform/resolvers/zod';
import UpdateProfileForm from '@modules/auth/components/profile/UpdateProfileForm';
import { SharedButton } from '@styles/shared';
import { defineAction, useAction } from '@core/router/action';
import * as AuthService from '@modules/auth/service';
import { pipe } from 'fp-ts/function';
import { useProfile } from '@modules/auth/profile-loader';

const actions = {
  update: defineAction({
    type: 'update',
    payload: updateProfileSchema,
    handler: ({ payload }) => AuthService.updateProfile(payload),
    flashOptions: { success: () => 'Compte modifié avec succès' },
  }),
};

const ProfilePage: FC = () => {
  const profile = useProfile();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<UpdateProfileParams>({
    resolver: zodResolver(updateProfileSchema),
    defaultValues: {
      firstName: profile.firstName ?? '',
      lastName: profile.lastName ?? '',
    },
  });

  const [loading, send] = useAction(actions.update);

  const pageProps: PageProps = {
    title: 'Mon compte',
    bottom: {
      right: <SharedButton btnType="save" loading={loading} onClick={handleFormSubmit} />,
    },
  };

  const handleUpdateProfile = (params: UpdateProfileParams) =>
    pipe(() => send(params), mapToEnhancedFormSubmitResult());

  return (
    <Page {...pageProps}>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdateProfile} preventLeave>
        <UpdateProfileForm email={profile.email} />
      </EnhancedForm>
    </Page>
  );
};

const profilePageRoute = defineRoute({
  component: ProfilePage,
  actions,
});

export default profilePageRoute;
