import React, { FC } from 'react';
import { Dashboard } from '@modules/dashboard/model';
import { Box, SimpleGrid, Title } from '@mantine/core';
import DashboardGroup from '@modules/dashboard/components/organisation/group/DashboardGroup';

interface DashboardOrganisationProps {
  dashboard: Dashboard;
}

const DashboardOrganisation: FC<DashboardOrganisationProps> = ({ dashboard }) => {
  return (
    <Box>
      <Title order={2}>{dashboard.organisationName}</Title>

      <SimpleGrid cols={3}>
        {dashboard.stats.map((stat, index) => (
          <DashboardGroup stats={stat} key={index} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default DashboardOrganisation;
