import React, { FC } from 'react';
import { DashboardStats } from '@modules/dashboard/model';
import { Paper, Table, Title } from '@mantine/core';

interface DashboardGroupProps {
  stats: DashboardStats;
}

const DashboardGroup: FC<DashboardGroupProps> = ({ stats }) => {
  const rows = stats.stats.map(stat => (
    <tr key={stat.weekNumber}>
      <td>{stat.weekNumber}</td>
      <td>{stat.nbParticipantAnswered}</td>
      <td>{stat.ParticipantTotal}</td>
    </tr>
  ));

  return (
    <Paper p={20} my={20}>
      <Title order={3}>Groupe {stats.group}</Title>

      <Table striped highlightOnHover withBorder fontSize="xs" mt={10}>
        <thead>
          <tr>
            <th>Numéro de semaine</th>
            <th>Nombre de réponse</th>
            <th>Nombre total de participants</th>
          </tr>
        </thead>

        <tbody>{rows}</tbody>
      </Table>
    </Paper>
  );
};

export default DashboardGroup;
