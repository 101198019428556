import styled from '@emotion/styled';

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageTop = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 30px;
  background: ${props => props.theme.white};
  border-bottom: 1px solid ${props => props.theme.colors.gray[2]};
`;

export const PageTopLeft = styled.div`
  flex: 1 1 auto;
`;

export const PageContentWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
  background-color: ${props => props.theme.colors.gray[1]};
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
`;

export const PageChildren = styled.div`
  flex: 1 1 auto;
  padding-bottom: 20px;
`;

export const PageBottom = styled.div`
  min-height: 56px;
  background: ${props => props.theme.white};
  border-top: 1px solid ${props => props.theme.colors.gray[2]};

  > div {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 15px;

    > div {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      padding: 7px 0;

      &.left {
        justify-content: flex-start;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }
`;
