import React, { FC } from 'react';

import { useForm } from 'react-hook-form';
import * as O from 'fp-ts/Option';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { HttpError, HttpStatusCode } from '@core/http';
import { AuthenticateParams, authenticateSchema } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { Alert, Anchor, Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';

interface LoginFormProps {
  loading: boolean;
  error: O.Option<HttpError>;
  onSubmit: (params: AuthenticateParams) => void;
}

const LoginForm: FC<LoginFormProps> = ({ loading, error, onSubmit }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<AuthenticateParams>({
    resolver: zodResolver(authenticateSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {HttpStatusCode.UNAUTHORIZED === error.status
              ? 'Email ou mot de passe incorrect.'
              : 'Une erreur technique est survenue.'}
          </Text>
        </Alert>
      ))}

      <TextInput
        id="email"
        type="email"
        label="E-mail"
        required
        placeholder="Saisir votre e-mail"
        error={!!errors.email}
        {...register('email')}
      />

      <PasswordInput
        mt="sm"
        id="password"
        label="Mot de passe"
        required
        placeholder="Saisir votre mot de passe"
        error={!!errors.password}
        {...register('password')}
      />

      <Group mt="md" position="center">
        <Button type="submit" loading={loading} disabled={loading}>
          Se connecter
        </Button>
      </Group>

      <Group mt="md" position="center">
        <Anchor component={Link} underline to="/password-reset/forgot" size="sm" weight="600">
          Mot de passe oublié ?
        </Anchor>
      </Group>
    </form>
  );
};

export default LoginForm;
