import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import * as UsersService from '@modules/users/service';
import * as TE from 'fp-ts/TaskEither';
import { CreateUserParams, UserId, userRoleColor, userRoleLabel, userSchema } from '@modules/users/model';
import Page, { PageProps } from '@layout/page/Page';
import { pipe } from 'fp-ts/function';
import * as EI from 'fp-ts/Either';
import { Badge, Group, Title } from '@mantine/core';
import { defineAction, useAction } from '@core/router/action';
import { EnhancedForm, mapToEnhancedFormSubmitResult, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import UserForm from '@modules/users/components/form/UserForm';
import { SharedButton } from '@styles/shared';
import DeleteModal from '@shared/components/modals/DeleteModal';
import { createNewTypeStringSchema } from '@shared/schemas';
import { getUserName } from '@modules/users/utils';
import { useProfile } from '@modules/auth/profile-loader';
import { sequenceS } from 'fp-ts/Apply';

const userIdSchema = z.object({ id: createNewTypeStringSchema<UserId>() });

const loader = defineLoader({
  params: userIdSchema,
  handler: ({ params }) =>
    httpTaskToResponseTask(
      sequenceS(TE.ApplyPar)({
        user: UsersService.getUser(params.id),
        organisations: UsersService.getOrganisations(),
      }),
    ),
});

const actions = {
  update: defineAction({
    type: 'update',
    params: userIdSchema,
    payload: userSchema,
    handler: ({ payload, params }) =>
      UsersService.updateUser(params.id, {
        lastName: payload.lastName,
        firstName: payload.firstName,
        role: payload.role,
        organisationId: payload.organisationId,
      }),
    flashOptions: {
      success: () => 'Utilisateur modifié avec succès',
    },
  }),
  delete: defineAction({
    type: 'delete',
    params: userIdSchema,
    handler: ({ params }) => UsersService.deleteUser(params.id),
    flashOptions: {
      success: () => 'Utilisateur supprimé avec succès',
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/users' : null),
  }),
};

const UserDetailPage: FC = () => {
  const { user, organisations } = useLoader<typeof loader>();

  const profile = useProfile();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<CreateUserParams>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      email: user.email ?? '',
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      role: user.role,
      organisationId: user.organisation?.id ?? '',
    },
  });

  const [loading, update] = useAction(actions.update);

  const [deleteLoading, deleteUser] = useAction(actions.delete);

  const name = getUserName('', user.firstName, user.lastName);

  const isMe = user.email === profile.email;

  const pageProps: PageProps = {
    title: (
      <Group spacing="sm">
        <Title>{name}</Title>

        <Badge color={userRoleColor[user.role]}>{userRoleLabel[user.role]}</Badge>
      </Group>
    ),
    seoTitle: name,
    breadcrumbs: [
      {
        title: 'Liste des utilisateurs',
        to: '/users',
      },
      {
        title: "Détail d'un utilisateur",
      },
    ],
    bottom: {
      left: !isMe ? <DeleteModal onDelete={deleteUser} loading={deleteLoading} /> : null,
      right: <SharedButton btnType="save" loading={loading} onClick={handleFormSubmit} />,
    },
  };

  const handleUpdateUser = (params: CreateUserParams) => pipe(() => update(params), mapToEnhancedFormSubmitResult());

  return (
    <Page {...pageProps}>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleUpdateUser} preventLeave>
        <UserForm isEdit organisations={organisations} />
      </EnhancedForm>
    </Page>
  );
};

const userDetailRoute = defineRoute({
  component: UserDetailPage,
  loader,
  actions,
});

export default userDetailRoute;
