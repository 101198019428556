import styled from '@emotion/styled';

import { createPolymorphicComponent, Group, GroupProps, Paper, PaperProps } from '@mantine/core';

export const AuthLayoutContainer = createPolymorphicComponent<'div', GroupProps>(styled(Group)`
  height: 100%;
  background-color: ${props => props.theme.colors.beige[6]};
`);

export const AuthLayoutContent = createPolymorphicComponent<'div', PaperProps>(styled(Paper)`
  width: 100%;
  max-width: 540px;
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.08);
`);

export const AuthLayoutLogoContainer = styled.div`
  text-align: center;
  padding-bottom: 20px;

  img {
    width: 150px;
  }
`;
