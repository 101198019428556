import { filterEmptyStringToNullable } from '@shared/utils/string';
import React, { ChangeEventHandler, FC, useEffect, useState, useTransition } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { TextInput, ThemeIcon } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

interface SearchFilterProps {
  defaultValue: string | null;
  onChange: (value: string | null) => void;
  className?: string;
}

const SearchFilter: FC<SearchFilterProps> = ({ defaultValue, onChange, className }) => {
  const [, startTransition] = useTransition();

  const [value, setValue] = useState<string>(() => defaultValue ?? '');

  useEffect(() => {
    startTransition(() => {
      setValue(defaultValue ?? '');
    });
  }, [defaultValue]);

  const debouncedOnChange = useDebouncedCallback((search: string | null) => onChange(search), 400);

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;

    setValue(value);
    debouncedOnChange(filterEmptyStringToNullable(value));
  };

  return (
    <TextInput
      value={value}
      rightSection={
        <ThemeIcon radius="md" size={25}>
          <IconSearch size="16" />
        </ThemeIcon>
      }
      placeholder="Rechercher"
      onChange={handleChange}
      sx={{ maxWidth: 375 }}
    />
  );
};

export default SearchFilter;
