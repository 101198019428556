import { pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import { filterEmptyStringToOption } from '@shared/utils/string';

export function getUserName(email: string, firstName?: string | null, lastName?: string | null) {
  return pipe(
    sequenceS(O.Apply)({
      firstName: filterEmptyStringToOption(firstName),
      lastName: filterEmptyStringToOption(lastName),
    }),
    O.fold(
      () => email,
      ({ firstName, lastName }) => `${firstName} ${lastName}`,
    ),
  );
}

export function getUserInital(email: string, firstName?: string | null, lastName?: string | null) {
  return pipe(
    sequenceS(O.Apply)({
      firstName: filterEmptyStringToOption(firstName),
      lastName: filterEmptyStringToOption(lastName),
    }),
    O.fold(
      () => email.charAt(0),
      ({ firstName, lastName }) => `${firstName.charAt(0)}${lastName.charAt(0)}`,
    ),
  ).toUpperCase();
}
