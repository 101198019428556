import React, { FC } from 'react';

import * as O from 'fp-ts/Option';

import { PasswordsFormBody, passwordsSchema } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert, Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';

interface PasswordsFormProps {
  email: string;
  loading: boolean;
  error: O.Option<string>;
  onSubmit: (params: PasswordsFormBody) => void;
}

const PasswordsForm: FC<PasswordsFormProps> = ({ email, loading, error, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordsFormBody>({
    resolver: zodResolver(passwordsSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {error}
          </Text>
        </Alert>
      ))}

      <TextInput mt={10} value={email} label="E-mail" disabled />

      <PasswordInput
        id="password"
        mt="sm"
        error={!!errors.password}
        placeholder="Entrez votre mot de passe"
        label="Mot de passe"
        required
        {...register('password')}
      />

      <PasswordInput
        id="confirmPassword"
        mt="sm"
        error={errors.confirmPassword?.message}
        placeholder="Confirmer le mot de passe"
        label="Confirmer le mot de passe"
        required
        {...register('confirmPassword')}
      />

      <Group position="center" mt={20}>
        <Button type="submit" loading={loading} disabled={loading}>
          Valider
        </Button>
      </Group>
    </form>
  );
};

export default PasswordsForm;
