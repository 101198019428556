import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { CreateUserParams, Organisations, UserRole } from '@modules/users/model';
import { Paper, Select, SimpleGrid, TextInput } from '@mantine/core';

interface UserFormProps {
  isEdit: boolean;
  organisations: Array<Organisations>;
}

const UserForm: FC<UserFormProps> = ({ isEdit, organisations }) => {
  const {
    watch,
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useEnhancedFormContext<CreateUserParams>();

  const handleChangeRole = (role: UserRole) => {
    setValue('role', role);
  };

  const handleChangeOrganisation = (organisation: string) => {
    setValue('organisationId', organisation);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Paper p="xl" withBorder={false}>
        <TextInput
          id="email"
          label="Email"
          placeholder="Email"
          required
          error={!!errors.email}
          disabled={isEdit}
          {...register('email')}
        />

        <SimpleGrid cols={2} pt={16}>
          <TextInput
            id="lastName"
            label="Nom"
            placeholder="Nom"
            required
            error={!!errors.lastName}
            {...register('lastName')}
          />

          <TextInput
            id="firstName"
            label="Prénom"
            placeholder="Prénom"
            error={!!errors.firstName}
            required
            {...register('firstName')}
          />

          <Select
            value={watch('role')}
            label="Rôle"
            placeholder="Choisir un rôle"
            data={[
              {
                label: 'Administrateur',
                value: UserRole.Admin,
              },
              {
                label: 'Animateur',
                value: UserRole.Animator,
              },
            ]}
            onChange={handleChangeRole}
            required
          />

          {watch('role') === UserRole.Animator ? (
            <Select
              value={watch('organisationId')}
              label="Organisation"
              placeholder="Choisir une organisation"
              data={organisations.map(organisation => ({
                label: organisation.name,
                value: organisation.id,
              }))}
              onChange={handleChangeOrganisation}
              required
            />
          ) : null}
        </SimpleGrid>
      </Paper>
    </form>
  );
};

export default UserForm;
