import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';

import usersListRoute from '@modules/users/ListPage';
import userDetailRoute from '@modules/users/DetailPage';
import createUserRoute from '@modules/users/CreatePage';

const usersRoutes = (
  <Route path="users">
    <Route index {...createRoute(usersListRoute)} />
    <Route path="new" {...createRoute(createUserRoute)} />
    <Route path=":id" {...createRoute(userDetailRoute)} />
  </Route>
);

export default usersRoutes;
