import { defineLoader, useParentLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import * as AuthService from '@modules/auth/service';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import { HttpStatusCode } from '@core/http';
import { redirect } from 'react-router-dom';
import { defineRoute } from '@core/router';
import { stringifyQueries } from '@shared/utils/queries';

const profileLoader = defineLoader({
  id: 'profile-loader',
  handler: ({ request }) => {
    return pipe(
      AuthService.getProfile(),
      T.chainIOK(res => () => {
        if (EI.isLeft(res)) {
          if (HttpStatusCode.UNAUTHORIZED === res.left.status) {
            throw redirect(`/login?${stringifyQueries({ referrer: new URL(request.url).pathname })}`);
          }

          throw res.left.toResponse();
        }

        return res.right;
      }),
    );
  },
});

const profileLoaderRoute = defineRoute({
  loader: profileLoader,
  shouldRevalidate: ({ currentUrl, defaultShouldRevalidate }) =>
    defaultShouldRevalidate && ['/profile', '/users/'].some(path => currentUrl.pathname.includes(path)),
});

export function useProfile() {
  return useParentLoader<typeof profileLoaderRoute.loader>('profile-loader');
}

export default profileLoaderRoute;
