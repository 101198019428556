import React, { FC, useCallback } from 'react';
import * as DashboardService from './service';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import Page, { PageProps } from '@layout/page/Page';
import DashboardOrganisation from '@modules/dashboard/components/organisation/DashboardOrganisation';
import { defineAction, useAction } from '@core/router/action';
import { SharedButton } from '@styles/shared';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(DashboardService.getDashboard()),
});

const actions = {
  export: defineAction({
    type: 'export',
    handler: () => DashboardService.getDashboardExport(),
    flashOptions: {
      success: () => 'Fichier exporté',
    },
  }),
};

const DetailPage: FC = () => {
  const dashboard = useLoader<typeof loader>();

  const [loading, exportFile] = useAction(actions.export);

  const handleExport = useCallback(() => {
    exportFile();
  }, [exportFile]);

  const pageProps: PageProps = {
    title: 'Tableau de bord',
    seoTitle: 'Tableau de bord',
    bottom: {
      right: <SharedButton btnType="export" loading={loading} onClick={handleExport} />,
    },
  };

  return (
    <Page {...pageProps}>
      {dashboard.map((elt, index) => (
        <DashboardOrganisation dashboard={elt} key={index} />
      ))}
    </Page>
  );
};

const dashboardDetailPage = defineRoute({
  component: DetailPage,
  loader,
  actions,
});

export default dashboardDetailPage;
