import { httpService, HttpTask } from '@core/http';
import { Dashboard } from '@modules/dashboard/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { downloadFile } from '@shared/utils/download';

const URI = '/dashboard';

export function getDashboard(): HttpTask<Array<Dashboard>> {
  return httpService.get(URI);
}

export function getDashboardExport(): HttpTask {
  return pipe(httpService.get<Blob>(`${URI}/export`, { responseType: 'blob' }, true), TE.chainIOK(downloadFile));
}
