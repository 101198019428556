import React, { FC } from 'react';
import * as UsersService from './service';
import { defineRoute } from '@core/router';
import Page, { PageProps } from '@layout/page/Page';
import { EnhancedForm, mapToEnhancedFormSubmitResult, useEnhancedForm } from '@shared/modules/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateUserParams, UserRole, userSchema } from '@modules/users/model';
import { defineAction, useAction } from '@core/router/action';
import { pipe } from 'fp-ts/function';
import UserForm from '@modules/users/components/form/UserForm';
import { SharedButton } from '@styles/shared';
import { HttpStatusCode } from '@core/http';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(UsersService.getOrganisations()),
});

const actions = {
  create: defineAction({
    type: 'create',
    payload: userSchema,
    handler: ({ payload }) => UsersService.createUser(payload),
    flashOptions: {
      success: () => 'Utilisateur ajouté avec succès',
      error: ({ error }) =>
        error.status === HttpStatusCode.CONFLICT ? 'Cet e-mail est déjà rattaché à un autre utilisateur' : true,
    },
  }),
};

const defaultValues: CreateUserParams = {
  email: '',
  firstName: '',
  lastName: '',
  role: UserRole.Animator,
  organisationId: '',
};

const CreateUserPage: FC = () => {
  const organisations = useLoader<typeof loader>();

  const { formRef, form, handleFormSubmit } = useEnhancedForm<CreateUserParams>({
    resolver: zodResolver(userSchema),
    defaultValues,
  });

  const [loading, create] = useAction(actions.create);

  const pageProps: PageProps = {
    title: 'Ajouter un utilisateur',
    breadcrumbs: [
      {
        title: 'Liste des utilisateurs',
        to: '/users',
      },
      {
        title: 'Ajouter un utilisateur',
      },
    ],
    bottom: {
      right: <SharedButton btnType="create" loading={loading} onClick={handleFormSubmit} />,
    },
  };

  const handleCreateUser = (params: CreateUserParams) =>
    pipe(
      () => create(params),
      mapToEnhancedFormSubmitResult(user => `users/${user.id}`),
    );

  return (
    <Page {...pageProps}>
      <EnhancedForm ref={formRef} form={form} onSubmit={handleCreateUser} preventLeave>
        <UserForm isEdit={false} organisations={organisations} />
      </EnhancedForm>
    </Page>
  );
};

const createUserRoute = defineRoute({
  component: CreateUserPage,
  loader,
  actions,
});

export default createUserRoute;
