import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  useNavigation,
} from 'react-router-dom';

import { createRoute } from '@core/router';
import Layout from '@layout/Layout';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import loginRoute from '@modules/auth/LoginPage';
import profilePageRoute from '@modules/auth/ProfilePage';
import forgotPasswordRoute from '@modules/auth/ForgotPasswordPage';
import activationRoute from '@modules/auth/ActivationPage';
import resetPasswordRoute from '@modules/auth/ResetPasswordPage';

import usersRoutes from '@modules/users/routes';
import dashboardRoutes from '@modules/dashboard/routes';
import profileLoaderRoute from '@modules/auth/profile-loader';
import logoutRoute from '@modules/auth/logout';

const NavigationLoader: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationLoader />}>
      <Route path="/login" {...createRoute(loginRoute)} />

      <Route path="/password-reset/forgot" {...createRoute(forgotPasswordRoute)} />
      <Route path="/password-reset/:token" {...createRoute(resetPasswordRoute)} />

      <Route path="/activation/:token" {...createRoute(activationRoute)} />

      <Route {...createRoute(profileLoaderRoute)}>
        <Route element={<Layout />}>
          <Route index element={<Navigate to="/dashboard" replace />} />

          <Route path="/profile" {...createRoute(profilePageRoute)} />

          {usersRoutes}
          {dashboardRoutes}

          <Route path="/logout" {...createRoute(logoutRoute)} />

          <Route path="*" element={<ErrorPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Route>,
  ),
);

export default router;
